import React from 'react';
import { connect } from "react-redux"
import styles from "../../components/landingPage.module.css"
import { Icon } from 'react-icons-kit'
import {plusSquare} from 'react-icons-kit/fa/plusSquare'
import {hSquare} from 'react-icons-kit/fa/hSquare'
import ScrollAnimation from 'react-animate-on-scroll';
import { Link, navigate } from "gatsby"
import SiteVersion from '../../components/changeSites';
import Footer from "../../components/landingFooter"
import MainLandingDisclaimerPopup from "../../components/prompts/disclaimers/main-landing/disclaimer-popup"
import {showNonUkResidentsUserSelectionDisclaimer} from "../../actions/nonUkResidents/user-selection"
import { getNonUkResidentsHCPLandingDisclaimerPopupConfig, getNonUkResidentsNonHCPLandingDisclaimerPopupConfig, getDefaultMainLandingDisclaimerPopupConfigsNonUKResidents} from "../../util/configs"
import NonUkResidentsLandingDisclaimer from "../../components/prompts/disclaimers/nonUkResidents/user-selection-disclaimer"
import SEO from "../../components/seo"
import { ValidateRoute, ShowDisclaimer} from "../../util/route-helper"
import {config} from "../../util/configs"

class LandingPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showPopUp : false,
            shouldRender: false,
            directLinkPopUpConfig: null

        }
    }
    componentDidMount(){
        let shouldRender = ValidateRoute();

        this.setState({shouldRender});
        
        ShowDisclaimer("NonUkResidents-Landing", getDefaultMainLandingDisclaimerPopupConfigsNonUKResidents(null, true), this);
        localStorage.setItem("NonUkResidents-Landing", JSON.stringify({ disclaimerShown: true }));
    }

    toggleUserSelectionDisclaimerPopup = (path, userType) => {
        let popupConfig = {}
        switch(userType){
            case "HCP":
                popupConfig = getNonUkResidentsHCPLandingDisclaimerPopupConfig(path);
                localStorage.setItem("nonukresidents-hcp", JSON.stringify({ disclaimerShown: true }));
                break;
            case "NonHCP":
                popupConfig = getNonUkResidentsNonHCPLandingDisclaimerPopupConfig(path);
                break;
        }
        this.props.showNonUkResidentsUserSelectionDisclaimer(popupConfig)
    }
   
    render() {
        const { shouldRender, directLinkPopUpConfig} = this.state;

        if(!shouldRender){
            return <React.Fragment />
        }

        return ( 
            <div>
            <SEO title="Non-UK Residents" />
            <div className={styles.landing_wrap}>
              <div className={styles.landing_logo}>
              <div className="container">
           
              <div className="row">
                 <div className="col-4 col-sm-4 col-md-6">
                 <Link to="/">
              <span className={styles.logo}></span>
              </Link>
                
                 </div>
                 <div className="col-8 col-sm-8 col-md-6 p-4 text-center">
                 
           
            
              <ul>
                
                  <li>
                  <Link to="/">
                    Investors
                  </Link> 
                  </li>
                  <li>
                  <a href={config.ukResidentsBaseUrl || '/'}>
                  UK Site
              </a>
                  </li>
              </ul>
                
                 </div>
             </div>
                    </div>
              </div>
                <div className="container">
                <SiteVersion/>
                        {directLinkPopUpConfig && directLinkPopUpConfig.show && <MainLandingDisclaimerPopup popupConfig={directLinkPopUpConfig} hide={() => this.setState({ directLinkPopUpConfig: null })} />}
                {this.props.nonUkResidentsUserSelectionDisclaimerPopup.show && <NonUkResidentsLandingDisclaimer popupConfig={this.props.nonUkResidentsUserSelectionDisclaimerPopup}/>}
                    <div className="row">
                        <div className={`col-12 col-sm-12 col-md-12 ${styles.welcome_section}`}>
                      
                        <ScrollAnimation animateIn="fadeInUp" delay={100}>
                            <h2>Dedicated to <span>developing</span> hormone therapeutics</h2>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" delay={700}>
                            <p>A global pharmaceutical company focused on chronic endocrine conditions</p>
                       </ScrollAnimation>
                        </div>
                      </div>
                    <div className="row justify-content-center">
                        <div className={`col-12 col-sm-12 col-md-12 col-lg-5  ${styles.our_services}`}>
                        <ScrollAnimation animateIn="fadeIn">
                            
                        <div onClick={() => this.toggleUserSelectionDisclaimerPopup("/NonUkResidents/HCP/home", "HCP")}>
                            <div className={`row ${styles.landing_page_sections}`}>
                                <div className="col-12 col-sm-12 col-md-12  pl-0">
                                    <div className={styles.card_border}>
                                    <div className={styles.card}>
                                        <div className={styles.content}>
                                            <h2 className={styles.title}>Non-UK<br/> Healthcare Professionals</h2>
                                        </div>
                                     </div>
                                    </div>
                                </div>
                                
                            </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                        <div className={`col-12 col-sm-12 col-md-12 col-lg-5   ${styles.our_services}`}>
                        <ScrollAnimation animateIn="fadeIn">
                        <Link to="/NonUkResidents/Non-HCP/home" state={{ukResidents: false}}>
                        <div className={`row  ${styles.landing_page_sections} ${styles.non_health}`}>
                                <div className="col-12 col-sm-12 col-md-12 pl-0">
                                <div className={styles.card_border}>
                                        <div className={styles.card}>
                                        <div className={styles.content}>
                                        <h2 className={styles.title}>Non-UK <br/> Patients/Public</h2>
                                       </div>
                                 </div>
                                </div>
                                   
                                 </div>
                                
                            </div>
                    </Link>
                    </ScrollAnimation>
                        </div>
                     
                    </div>
                </div>
            </div>
            <Footer/>
            </div>
         );
    }
}
 
const mapStateToProps = state => ({
    nonUkResidentsUserSelectionDisclaimerPopup: state.nonUkResidentsUserSelectionDisclaimerPopup
})

const mapDispatchToProps = () => {
    return {
        showNonUkResidentsUserSelectionDisclaimer
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(LandingPage)