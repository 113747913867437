import React from 'react';  
import { connect } from "react-redux"
import  styles from "./disclaimer-popup.module.css";
import {DisclaimerPopupSchema} from "../../../../schemas/prompts/disclaimers/nonUkResidents/user-selection-disclaimer"
import {hideNonUkResidentsUserSelectionDisclaimer} from "../../../../actions/nonUkResidents/user-selection"

class Popup extends React.Component {
    constructor(props){
        super(props);
    }

    closePopup = () => {
        let popupConfig = new DisclaimerPopupSchema()
        this.props.hideNonUkResidentsUserSelectionDisclaimer(popupConfig.schema)
    }

    closeHandler = async (closeCallback) => {
        if(typeof closeCallback === "function"){
            await closeCallback()
        }
        this.closePopup()
    }

    okHandler = async (okCallback) => {
        okCallback()
        this.closePopup()
    }

    render() {  
        const { show, message, okCallback, closeCallback, okLabel, closeLabel} = this.props.popupConfig;

        if(!show) return <React.Fragment />

        return (  
            <div className={styles.popup}>  
            <div className={styles.popup_inner}>  
            <p>
                {message}
            </p> 
            <button className={"btn"} onClick={async () => await this.okHandler(okCallback)}>
                {okLabel}
            </button>

            <button  style={{marginLeft: '10px'}} className={"btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                {closeLabel}
            </button>  
            </div>  
            </div>  
        );  
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = () => {
    return {
        hideNonUkResidentsUserSelectionDisclaimer
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(Popup)