import React from 'react';
import { Icon } from 'react-icons-kit'
import {facebook} from 'react-icons-kit/fa/facebook'
import {twitter} from 'react-icons-kit/fa/twitter'
import {googlePlus} from 'react-icons-kit/fa/googlePlus'
import {linkedin} from 'react-icons-kit/fa/linkedin'
import {dribbble} from 'react-icons-kit/fa/dribbble'
import Link from "gatsby-link"
import TwitterModal from "../components/_shared/footer-disclaimers/Modal/twitter"
import LinkedinModal from "../components/_shared/footer-disclaimers/Modal/linkedin"
class Footer extends React.Component {
    constructor(props){
        super()
        this.state = {}
    }
    openModal = (type) => {
        this.disableScroll()
        switch(type){
            case "twittermodal": 
            this.setState({
                twittermodal: true,
    
            })
            break;

            case "linkedinmodal": 
            this.setState({
                linkedinmodal: true,
    
            })
            break;
          
            
            default: 
                 return 
        }
     
       
    }
    
    
    closeModal = (type) => {
        this.enableScroll()
        switch(type){
            case "twittermodal": 
            this.setState({
                twittermodal: false,
    
            })
            break;

            case "linkedinmodal": 
            this.setState({
                linkedinmodal: false,
    
            })
            break;
          
          
            
            default: 
                 return 
        }
     
       
    }
    disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }
    enableScroll = () => {
      
        document.body.style.overflow = 'auto'
    }
    render() { 
        let {twittermodal, linkedinmodal} = this.state;
        return ( 
            <>

<footer className="page-footer font-small stylish-color-dark pt-4">

  <div className="container text-center text-md-left">

  
  <div className="container text-center text-md-left">

   <p>
                                Diurnal Limited (Company Number: 05237326) is registered in England & Wales. Registered office: Cardiff Medicentre, Heath Park, Cardiff CF14 4UJ, UK.
   </p>
  </div>
  </div>
  <div className={"bottom_footer"}>

  
  <div className="footer-copyright  py-3">
      <div className={"container"} style={{borderTop: '1px solid #fff', padding: '30px 0 10px', fontSize: '15px'}}>
          <div className={"row"}>
              <div className={"col-12 col-md-4 text-left"}>
               ©{new Date().getFullYear()}  Diurnal Ltd. All Rights Reserved 
          {` `}
              </div>
              <div className={"col-12 col-md-5 text-center"}>
             
              </div>
              <div className={"col-12 col-md-3 text-right"}>
              <a onClick={() => this.openModal("twittermodal")}  ><Icon icon={twitter} /></a>
              <a onClick={() => this.openModal("linkedinmodal")}  ><Icon icon={linkedin} /></a>
              </div>

          </div>
   {/* MODALS */}
    
  <TwitterModal closeModal={() => this.closeModal("twittermodal")} isActive={twittermodal}/> 
  <LinkedinModal closeModal={() => this.closeModal("linkedinmodal")} isActive={linkedinmodal}/> 
      </div>
 
  </div>
 
  </div>
 


</footer>
            </>
         );
    }
}
 
export default Footer;