import React from 'react';
import  styles from "./disclaimer-popup.module.css";
import {DisclaimerPopupSchema} from "../../../../schemas/prompts/disclaimers/main-landing"

class Popup extends React.Component {
    constructor(props){
        super(props);
    }

    closePopup = () => {
        this.props.hide()
    }

    closeHandler = async (closeCallback) => {
        this.closePopup()
        if(typeof closeCallback === "function"){
            await closeCallback()
        }
    }

    okHandler = async (okCallback) => {
        console.log("Ok clicked...");
        okCallback()
        this.closePopup();
    }

    render() {  
        const { show, message, okCallback, closeCallback, okLabel, closeLabel, isDirectAccess} = this.props.popupConfig;

        if(!show) return <React.Fragment />

        return (  
            <div className={`${styles.popup}${isDirectAccess ? ' ' + styles.popup_blur_background : ''}`}>
            <div className={styles.popup_inner}>  
            <p>
                {message}
            </p> 
            <button className={"btn"} onClick={async () => await this.okHandler(okCallback)}>
                {okLabel}
            </button>

            <button  style={{marginLeft: '10px'}} className={"btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                {closeLabel}
            </button>  
            </div>  
            </div>  
        );  
    }
}

export default Popup